import garden from './images/garden.jpg';
import shibainu from './images/shibainu.jpeg';
import './App.css';
import ShiboArtifact from './abi/Shibo.json';

/* function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={shibadogs} className="App-shibadogs" alt="Shiba Dogs in Garden" width="500" height="500" />
        <p></p>
      </header>
    </div>
  );
}
export default App; */

import { useState, useEffect } from 'react';

function App() {

  //***********************
  const ethers = require("ethers")
  const [message, setMessage] = useState('');
  let transaction;
  const mintToken = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        // Request account access if needed
        await window.ethereum.request({ method: 'eth_requestAccounts' });

        // Get the chain ID
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });

        // Validate the chain ID
        if (chainId !== '0x89') {
          // console.error('Please connect to the Polygon POS mainnet.');
          setMessage('Please connect to the Polygon POS mainnet.');
          return;
        }

        // Get the provider from Metamask
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();

        // const provider = new ethers.providers.Web3Provider(window.ethereum);
        // const signer = provider.getSigner();

        // The contract address and ABI
        const contractAddress = '0xC630EffD39E685AD23B9ebafdFdB269bD49cBEE7';
        const contractABI = ShiboArtifact.abi;

        // Create a new contract instance
        const contract = new ethers.Contract(contractAddress, contractABI, signer);

        // Call the mint function
        transaction = await contract.mint();
        // Wait for the transaction to be mined
        await transaction.wait();

        setMessage('Minting successful! You now own a Shib Owner Token.');
      } catch (error) {
        let errorMessage;
        if (error.data && error.data.message) {
            errorMessage = error.data.message;
        } else if (error.message) {
            errorMessage = error.message;
        }
        if (errorMessage && errorMessage.includes("Must own at least one SHIBTOKEN")) {
          setMessage("You must own at least one Shib token to be able to mint a Shib Owner (SHO) token.");
        } else if (errorMessage && errorMessage.includes("You have already minted your token")) {
          setMessage("You have already minted a Shib Owner (SHO) token. Only one SHO token may be minted per address.");
        } else {
          setMessage("Error interacting with contract.", error);
        }
      }
    } else {
      console.error('Ethereum object not found. Install Metamask.');
      setMessage('Please install Metamask.');
    }
  };

  //***********************

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth || document.documentElement.clientWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight || document.documentElement.clientHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth || document.documentElement.clientWidth);
      setViewportHeight(window.innerHeight || document.documentElement.clientHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sceneStyles = {
    height: `${viewportHeight}px`,
    width: `${viewportWidth}px`
  };

  return (
    <div>
      <div className="App">
        <div className="mainhome">
          <a-scene style={sceneStyles} embedded>
            <a-assets>
              <img id="sky" crossOrigin src={garden} alt="Garden" />
            </a-assets>
            <a-sky src="#sky" />
          </a-scene>
          <div className="overlay">
            <br/><br/><br/><br/><br/><br/>
            <h1 style={{ color: 'white', fontSize: '43px' }}>Shib Owner (SHIBO) Token</h1>
            <div className="arrow-container">
              {/* <div className="arrow-down"></div> */}
              <div className="arrow-down"></div>
            </div>
          </div>
        </div>
        <div className="shibinfo">
          <div className="fetchtokeninfo">
            <br/>
            <img crossOrigin src={shibainu} alt="Shiba Inu Dog" width="200" height="200" />
            <br/>
            <h1>Fetch your Shiba Inu Owner Token on the Polygon Network.</h1>
            <p style={{fontSize: '20px', margin: '0px 15% 0px 15%'}}>As for all domesticated animals, all Shiba Inu dogs in the world have an owner. Similarly, every address which owns at least one Shib Token is an owner, and is entitled to mint, freely, a Shib Owner (Shibo - SHO) Token.</p>
            <br/>
            <p style={{fontSize: '20px', margin: '0px 15% 0px 15%'}}>Only one SHO Token can be created by each address owning a Shib Token, currently or in the future, and therefore the amount of tokens minted will be limited to the number of Shib owners on the Polygon Blockchain.</p>
          </div>
        </div>
      </div>
      <div className='alert'>{message && <p>{message}</p>}</div>
      <div className="grid-container">
        <div className="grid-item-label"><h1>Fetch Shibo (SHO) Token</h1></div>
        <div className="grid-item-button"><button type="button" id='tokButton' onClick={mintToken}>Mint Token</button></div>
      </div>
      <div className="email">
        <div className="col-md-4 d-none d-md-block"></div>
        <div className="col-md-4"><p style={{ textAlign: 'center', paddingTop: '20px' }}><a href="mailto:support@cryptoastrum.org" style={{ color: 'black' }}>support@shibownertoken.com</a></p></div>
        <div className="col-md-4 d-none d-md-block"></div>
      </div>
    </div>
  );
}

export default App;

